import React, { useState, useCallback, useEffect, useRef } from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Layout from "../components/common/Layout"
import styles from "../css/platforms.module.css"
import useOnScreen from "../components/hooks/useOnScreen"

const PlatformItem = ({
    title = "",
    subTitle = "",
    tags = [],
    content = "",
    children,
    imgFluid,
    reverse = false,
    bg = "#000",
    changeBg,
}) => {
    const ref = useRef()
    const onScreen = useOnScreen(ref)

    useEffect(() => {
        if (onScreen) {
            changeBg(bg)
        }
    }, [onScreen, changeBg, bg])

    const img = (
        <div className="col-md-6 text-center mb-5 order-1 order-md-0 align-self-end">
            <Img alt={title} fluid={imgFluid} className="img-fluid" />
        </div>
    )

    return (
        <div className={styles.section}>
            <div className="container-fluid order-2 order-md-0 pl-md-5 pr-md-5" ref={ref}>
                <div className="row">
                    {reverse && img}
                    <div className={`col-md-6 mb-5 ${styles.alternate}`}>
                        <div className="pl-md-4 pr-md-4">
                            <h2 className={styles.title}>{title}</h2>
                            {subTitle && (
                                <h3 className={styles.subTitle}>{subTitle}</h3>
                            )}
                            <div className="pt-3 pb-3">
                                {tags.map(x => (
                                    <span className={styles.tag} key={x}>
                                        {x}
                                    </span>
                                ))}
                            </div>
                            <p className={styles.content}>{content}</p>
                            {children}
                        </div>
                    </div>
                    {!reverse && img}
                </div>
            </div>
        </div>
    )
}

const DbmIcon = ({ iconName, desc = "" }) => {
    return (
        <div className="col-md col-6 text-center">
            <img className="w-100" src={`/images/dbm/${iconName}.svg`} />
            <p className="f-14">{desc}</p>
        </div>
    )
}

const Platforms = ({ data }) => {
    const [bg, setstate] = useState("5FBB4E")
    const { dbm, mediaMath, tubeMogul, inmobi, adform, tradeDesk, turn } = data

    const changeBg = useCallback(bg => {
        setstate(bg)
    }, [])

    return (
        <Layout>
            <div
                style={{ backgroundColor: bg, transition: "all 0.5s ease 0s" }}
            >
                <PlatformItem
                    title="DBM"
                    subTitle="Double click Bid Manager"
                    tags={["Precise targeting", "Optimization"]}
                    content="Reach always-connected consumers when and where it
                matters with Google’s complete programmatic buying
                platform. DoubleClick Bid Manager gives you access
                to top-tier inventory across screens and formats,
                and the real-time insights you need to make your
                buys count."
                    imgFluid={dbm.childImageSharp.fluid}
                    bg="#5FBB4E"
                    changeBg={changeBg}
                >
                    <div className="row justify-content-center">
                        <DbmIcon
                            iconName="targeting"
                            desc="Precise targeting and optimization"
                        />
                        <DbmIcon
                            iconName="unified-inventory"
                            desc="A single access point"
                        />
                        <DbmIcon
                            iconName="cross-screen"
                            desc="Cross-screen made simple"
                        />
                        <DbmIcon
                            iconName="premium-video"
                            desc="Premium video inventory"
                        />
                        <DbmIcon
                            iconName="fraud-protection"
                            desc="Industry-leading fraud protection"
                        />
                    </div>
                </PlatformItem>
                <PlatformItem
                    title="Media Math"
                    subTitle="Double click Bid Manager"
                    tags={[
                        "Up to 5 Billion Opps",
                        "Less than 40MS Response",
                        "10TB per Model",
                    ]}
                    content="Media math is a leader in both the DSP wave and DMP wave. It combines audience data, omnichannel media and intelligent machine learning in a single programmatic platform. Mediamath gives marketers the tools to execute smart marketing at scale and to drive truly incremental business value. We help power good advertising experiences that are relevant and meaningful across every channel, format and screen."
                    imgFluid={mediaMath.childImageSharp.fluid}
                    reverse
                    bg="#3f2caa"
                    changeBg={changeBg}
                />
                <PlatformItem
                    title="Tube Mogul"
                    tags={[
                        "Cross Channel",
                        "End-to-End",
                        "Audience at the Core",
                    ]}
                    content="Adobe Advertising Cloud, Formerly Adobe Media Optimizer, is an independent, end-to-end platform for managing advertising across traditional TV and digital formats. It gives you a single programmatic solution for delivering TV, search, Display and social advertising across any screen, in any format."
                    imgFluid={tubeMogul.childImageSharp.fluid}
                    bg="#e74c3c"
                    changeBg={changeBg}
                />
                <PlatformItem
                    title="Inmobi"
                    subTitle="MOBILE MARKETING AT SCALE"
                    tags={[
                        "Brand Engagement",
                        "User Acquisition",
                        "Remarketing",
                    ]}
                    content="Reach over 1.5 billion mobile users across 32000 premium mobile properties. Leverage every mobile moment to engage users and maximize mobile revenues."
                    imgFluid={inmobi.childImageSharp.fluid}
                    reverse
                    bg="#000"
                    changeBg={changeBg}
                />
                <PlatformItem
                    title="Adform"
                    tags={["Identify", "Track", "Engage"]}
                    content="The complexity of modern advertising campaigns requires a full suite of complementary tools which allow you to identify, track and engage with your audience across a wide range of different channels. With Adform, we provide you with a choice: Advertiser Edge, the industry’s most complete full-stack offering, includes our stand-alone Demand Side Platform, Ad Server, Data Management Platform and robust collection of self-service creative tools."
                    imgFluid={adform.childImageSharp.fluid}
                    bg="#01b5ac"
                    changeBg={changeBg}
                />
                <PlatformItem
                    title="Trade Desk"
                    tags={[
                        "Lookalike modeling",
                        "Data element report",
                        "The Data Marketplace",
                    ]}
                    content={
                        <>
                            The key to smart decisions is in the data. Reach
                            relevant audiences, make informed, real-time
                            decisions, and maximize the power of cross-device
                            insights with The Trade Desk's fully integrated DMP.
                            <br /> Tradedesk makes sure your DMP advertising
                            campaigns are fully charged through our integrations
                            with leading data providers and exclusive access to
                            The Data Alliance. The largest advertisers in the
                            world rely on The Trade Desk for our closed-loop
                            technology stack that includes an ad server,
                            bidders, and data management tools, with every piece
                            of data earmarked with an owner.
                        </>
                    }
                    imgFluid={tradeDesk.childImageSharp.fluid}
                    reverse
                    bg="#f1c40f"
                    changeBg={changeBg}
                />
                <PlatformItem
                    title="Turn"
                    subTitle="Global Reach and Scale! Amazing performance!"
                    tags={[
                        "400+ software releases annually",
                        "6 petabytes of real-time data processed",
                    ]}
                    content=" The infrastructure that Amobee runs on is without parallel in the marketing discipline. Among advertising professionals, it’s considered the fastest in the world. It’s highly scalable—though it already embraces half of the entire Internet—highly efficient, because it has to be to handle such massive data volumes, and built with stringent standards of redundancy for the security of our clients. Here is a small sampling of the numbers that only begin to describe our technological immensity."
                    imgFluid={turn.childImageSharp.fluid}
                    bg="#3498db"
                    changeBg={changeBg}
                />
            </div>
        </Layout>
    )
}

export const query = graphql`
    {
        dbm: file(relativePath: { eq: "platform-page/dbmimg.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        mediaMath: file(relativePath: { eq: "platform-page/mediamath.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        tubeMogul: file(relativePath: { eq: "platform-page/tubemogul.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        inmobi: file(relativePath: { eq: "platform-page/inmobi.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        adform: file(relativePath: { eq: "platform-page/adform.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        tradeDesk: file(relativePath: { eq: "platform-page/tradedesk.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        turn: file(relativePath: { eq: "platform-page/amobee.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export default Platforms
